<!-- Topbar -->
<nav class="topbar topbar-inverse topbar-expand-md topbar-sticky">
  <div class="container">

    <div class="topbar-left">
      <a class="topbar-brand" href="/">
        <h4 class="logo-default">Skillustrator</h4>
        <h4 class="logo-inverse">Skillustrator</h4>
      </a>
    </div>


    <div class="topbar-right">
      <a href="/notyet" class="btn btn-sm btn-white mr-4" (click)="NavigatedToNotYet('LoginButton')">Login</a>
      <a href="/notyet" class="btn btn-sm btn-primary hidden-sm-down"
        (click)="NavigatedToNotYet('RegisterButton')">Register</a>
    </div>

    <!-- <div class="topbar-right">
          <ul class="topbar-nav nav">
            <li class="nav-item"><a class="nav-link active" href="index.html">Home</a></li>
            <li class="nav-item">
              <a class="nav-link" href="#">Samples <i class="fa fa-caret-down"></i></a>
              <div class="nav-submenu">
                <a class="nav-link" href="demo-helpato.html">Helpato</a>
                <a class="nav-link" href="demo-trello.html">Trello</a>
                <a class="nav-link" href="demo-gmail.html">Gmail</a>
                <a class="nav-link" href="demo-skype.html">Skype</a>
                <a class="nav-link" href="demo-github.html">GitHub</a>
                <a class="nav-link" href="demo-app.html">Mobile App</a>
                <a class="nav-link" href="demo-bootstrap.html">Bootstrap</a>
                <a class="nav-link" href="demo-slack.html">Slack</a>
                <a class="nav-link" href="demo-zendesk.html">Zendesk</a>
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#">Headers <i class="fa fa-caret-down"></i></a>
              <div class="nav-submenu">
                <a class="nav-link" href="header-color.html">Solid Color</a>
                <a class="nav-link" href="header-gradient.html">Gradient</a>
                <a class="nav-link" href="header-particle.html">Particle</a>
                <a class="nav-link" href="header-typing.html">Typing Text</a>
                <a class="nav-link" href="header-image.html">Background Image</a>
                <a class="nav-link" href="header-video.html">Background Video</a>
                <a class="nav-link" href="header-parallax.html">Parallax</a>
                <a class="nav-link" href="header-slider.html">Slider</a>
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#">Blocks <i class="fa fa-caret-down"></i></a>
              <div class="nav-submenu cols-2">
                <a class="nav-link" href="block-feature.html">Feature</a>
                <a class="nav-link" href="block-feature-textual.html">Textual feature</a>
                <a class="nav-link" href="block-content.html">Content</a>
                <a class="nav-link" href="block-portfolio.html">Portfolio</a>
                <a class="nav-link" href="block-pricing.html">Pricing</a>
                <a class="nav-link" href="block-cta.html">Call to action</a>
                <a class="nav-link" href="block-testimonial.html">Testimonial</a>
                <a class="nav-link" href="block-team.html">Team</a>
                <a class="nav-link" href="block-contact.html">Contact</a>
                <a class="nav-link" href="block-signup.html">Signup</a>
                <a class="nav-link" href="block-subscribe.html">Subscribe</a>
                <a class="nav-link" href="block-partner.html">Partner</a>
                <a class="nav-link" href="block-topbar.html">Topbar</a>
                <a class="nav-link" href="block-footer.html">Footer</a>
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#">Blog <i class="fa fa-caret-down"></i></a>
              <div class="nav-submenu">
                <a class="nav-link" href="blog-classic.html">Layout classic</a>
                <a class="nav-link" href="blog-grid.html">Layout grid</a>
                <a class="nav-link" href="blog-list.html">Layout list</a>
                <a class="nav-link" href="blog-sidebar.html">Layout sidebar</a>
                <a class="nav-link" href="blog-single.html">Single post</a>
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#">Shop <i class="fa fa-caret-down"></i></a>
              <div class="nav-submenu">
                <a class="nav-link" href="shop-list.html">Product list</a>
                <a class="nav-link" href="shop-single.html">Single product</a>
                <a class="nav-link" href="shop-cart.html">Cart overview</a>
                <a class="nav-link" href="shop-checkout.html">Checkout</a>
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#">Pages <i class="fa fa-caret-down"></i></a>
              <div class="nav-submenu">
                <a class="nav-link" href="page-how-it-works.html">How it works</a>
                <a class="nav-link" href="page-pricing.html">Pricing</a>
                <a class="nav-link" href="page-portfolio.html">Portfolio</a>
                <a class="nav-link" href="page-project.html">Project</a>
                <a class="nav-link" href="page-job.html">Jobs</a>
                <a class="nav-link" href="page-job-single.html">Job Info</a>
                <a class="nav-link" href="page-demo.html">Request Demo</a>
                <a class="nav-link" href="page-press.html">Press</a>
                <a class="nav-link" href="page-about.html">About</a>
                <a class="nav-link" href="page-contact.html">Contact</a>
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#">Extra <i class="fa fa-caret-down"></i></a>
              <div class="nav-submenu">
                <a class="nav-link" href="page-login.html">Login</a>
                <a class="nav-link" href="page-register.html">Register</a>
                <a class="nav-link" href="page-faq.html">FAQ</a>
                <a class="nav-link" href="page-coming-soon.html">Coming soon</a>
                <a class="nav-link" href="page-privacy.html">Privacy Policy</a>
                <a class="nav-link" href="page-404.html">Error 404</a>
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#">Doc <i class="fa fa-caret-down"></i></a>
              <div class="nav-submenu align-right">
                <a class="nav-link" href="doc-element.html">Elements</a>
                <a class="nav-link" href="doc-component.html">Components</a>
                <a class="nav-link" href="doc-section.html">Sections</a>
                <a class="nav-link" href="doc-card.html">Cards</a>
                <a class="nav-link" href="doc-typography.html">Typography</a>
                <a class="nav-link" href="doc-utility.html">Utility Classes</a>
              </div>
            </li>
          </ul>
        </div> -->

  </div>
</nav>
<!-- END Topbar -->





<!-- Header -->
<header class="header fadeout header-inverse pb-0 h-fullscreen"
  style="background-image: linear-gradient(to bottom, #243949 0%, #517fa4 100%);">
  <canvas class="constellation" width="1536" height="451"></canvas>

  <div class="container">
    <div class="row h-full">
      <div class="col-12 text-center align-self-center">
        <h1 class="fs-50 fw-600 lh-15 hidden-sm-down">Connect the dots <br /> between your <span class="text-primary"
            data-type="Skillsets, Opportunities, Clients, Quals, People"></span><span class="typed-cursor"
            style="animation-iteration-count: 0;"></span></h1>
        <h1 class="fs-35 fw-600 lh-15 hidden-md-up">Connect the dots between your <br><span class="text-primary"
            data-type="Skillsets, Opportunities, Clients, Quals, People"></span><span class="typed-cursor"
            style="animation-iteration-count: 0;"></span></h1>
        <br>
        <p class="fs-20 hidden-sm-down"><strong>Skillustrator</strong> is an app for better skills management across
          your teams and projects.</p>
        <p class="fs-16 hidden-md-up"><strong>Skillustrator</strong> is an app for better skills management across your
          teams and projects.</p>
        <br>
        <hr class="w-60 hidden-sm-down">
        <br>
        <a class="btn btn-xl btn-round btn-primary fs-20 fw-500 w-350 shadow-3 hidden-sm-down" href="/notyet"
          (click)="NavigatedToNotYet('GetStartedForFree')">Get Started For Free</a>
        <a class="btn btn-lg btn-round btn-primary w-250 shadow-3 hidden-md-up" href="/notyet"
          (click)="NavigatedToNotYet('GetStartedForFree')">Get Started For Free</a>
        <br>
        <p class="mt-2 mt-md-4"><a href="/notyet" (click)="NavigatedToNotYet('LikeFreeFree')"><small>Like...free,
              free.</small></a></p>
      </div>

      <div class="col-12 align-self-end text-center pb-70">
        <a class="scroll-down-2 scroll-down-inverse" href="#" data-scrollto="section-demo"><span></span></a>
      </div>
    </div>
  </div>
</header>
<!-- END Header -->


<!-- Main container -->
<main class="main-content">

  <!--
      |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
      | Feature 9
      |‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒‒
      !-->

  <section class="section overflow-hidden py-120">
    <div class="container-wide">
      <div class="row">


        <div class="offset-1 col-10 col-lg-6 offset-lg-1 text-center text-lg-left">
          <h2>Know what you know.</h2>
          <p class="lead">Stop missing out on opportunities for business and growth. Unlock the full potential of your
            workforce.</p>

          <br>

          <div class="row gap-y">

            <div class="col-12 col-md-6">
              <i class="fa fa-binoculars text-primary fs-30 mb-3"></i>
              <h6 class="text-uppercase mb-3">"Hey, who knows [x]?"</h6>
              <p class="fs-14">Quickly understand your organization's expertise. No more mass e-mails or slack messages
                to surface skills.</p>
            </div>


            <div class="col-12 col-md-6">
              <i class="fa fa-wrench text-primary fs-30 mb-3"></i>
              <h6 class="text-uppercase mb-3">"Who can we staff?"</h6>
              <p class="fs-14">Understand a client's needs and who can meet them. Project match-making made simple.</p>
            </div>


            <div class="col-12 col-md-6">
              <i class="fa fa-check-square text-primary fs-30 mb-3"></i>
              <h6 class="text-uppercase mb-3">"Where have we done this before?"</h6>
              <p class="fs-14">Gain strategic insight for proposals and have narratives at the ready.</p>
            </div>


            <div class="col-12 col-md-6">
              <i class="fa fa-bar-chart text-primary fs-30 mb-3"></i>
              <h6 class="text-uppercase mb-3">"Where should we invest?"</h6>
              <p class="fs-14">Plan your capability growth to match your demand. Easily chart paths forward for your
                team.</p>
            </div>

          </div>

        </div>


        <div class="col-lg-5 align-self-center">
          <img class="shadow-3 mt-40"
            src="https://images.unsplash.com/photo-1472722266948-a898ab5ff257?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=640&h=419&q=80"
            alt="..." data-aos="slide-left" data-aos-duration="1500">
        </div>


      </div>
    </div>
  </section>

  <section class="section section-inverse py-120"
    style="background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);">
    <div class="container">
      <header class="section-header">
        <h2 class="fs-45">Dive in!</h2>
        <hr>
        <p class="lead">There's never been a better time to start putting the puzzle pieces together. <br />Create an
          account for your organization and track things within minutes.</p>
      </header>

      <p class="text-center">
        <a class="btn btn-xl btn-primary w-250" href="/notyet" (click)="NavigatedToNotYet('SignUp')">Sign Up</a><br>
        <small><a class="text-white" href="/notyet" (click)="NavigatedToNotYet('PsstDoIt')">Pssst....do it!</a></small>
      </p>
    </div>
  </section>


  <section class="section section-inverse"
    style="background-image: url(https://images.unsplash.com/photo-1515787366009-7cbdd2dc587b?auto=format&fit=crop&w=1400&q=80)"
    data-overlay="9">
    <div class="container text-center">

      <h3>Or, Start With Your Reading Skills. <i class="fa fa-smile-o" aria-hidden="true"></i></h3>
      <br>
      <p class="lead">Subscribe to our newsletter and receive the latest news from Skillustrator.<br />We won't contact
        you a bunch, but we might ask your opinion occasionally.</p>

      <br><br>

      <form class="form-inline form-glass justify-content-center"
        action="https://killeencode.us19.list-manage.com/subscribe/post?u=76b5ecf5c4782e5a3278ee84b&amp;id=cd7e7f9c64"
        method="post" target="_blank" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form">

        <div class="form-group">
          <div class="input-group input-group-lg">
            <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
            <input type="email" id="mce-EMAIL" name="EMAIL" class="form-control" placeholder="Email Address"
              (focus)="EmailFocused()" (blur)="EmailUnfocused()" (input)="EmailTyping()" required>
          </div>
        </div>
        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text"
            name="b_76b5ecf5c4782e5a3278ee84b_cd7e7f9c64" tabindex="-1" value=""></div>
        <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"
          class="btn btn-lg btn-primary ml-12" (click)="Subscribed()">

      </form>

      <br><br><br>

      <div class="social social-boxed social-rounded social-inverse text-center">
        <a class="social-twitter" href="https://twitter.com/killeencode"><i class="fa fa-twitter"></i></a>
        <a class="social-facebook" href="https://www.facebook.com/KilleenCode"><i class="fa fa-facebook"></i></a>
      </div>

    </div>
  </section>
  <section class="section">
    <div class="container">

      <h2 class="text-center">Let's Get In Touch</h2>
      <p class="text-center">Question? Just saying hello? No matter what, we'd love to hear from you.</p>

      <br><br>

      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3">

          <form name="skillustrator-contact" class="p-30 bg-gray rounded" method="post"
            action="https://prod-60.eastus2.logic.azure.com:443/workflows/96716b789c86448593d9b39fad8f0e19/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=r2uwJtVKDNxvvocTUmAKchoqCjye3me56XO47ou_2Ak"
            target="_blank">
            <input type="hidden" name="form-name" value="skillustrator-contact" />

            <!-- <div class="alert alert-success">We received your message and will contact you back soon.</div> -->

            <div class="row">
              <div class="form-group col-12 col-md-6">
                <input class="form-control form-control-lg" type="text" name="name" placeholder="Your Name"
                  (focus)="ContactFormNameFocused()" (blur)="ContactFormNameUnfocused()"
                  (input)="ContactFormNameTyping()">
              </div>

              <div class="form-group col-12 col-md-6">
                <input class="form-control form-control-lg" type="email" name="email" placeholder="Your Email Address"
                  (focus)="ContactFormEmailFocused()" (blur)="ContactFormEmailUnfocused()"
                  (input)="ContactFormEmailTyping()">
              </div>
            </div>


            <div class="form-group">
              <textarea class="form-control form-control-lg" rows="4" placeholder="Your Message" name="message"
                (focus)="ContactFormMessageFocused()" (blur)="ContactFormMessageUnfocused()"
                (input)="ContactFormMessageTyping()"></textarea>
            </div>

            <div class="text-center">
              <button class="btn btn-lg btn-primary" type="submit" (click)="ContactFormSubmitted()">Submit
                Enquiry</button>
            </div>
          </form>

        </div>
      </div>

    </div>
  </section>


</main>
<!-- END Main container -->






<!-- Footer -->
<footer class="site-footer">
  <div class="container">
    <div class="row gap-y align-items-center">
      <div class="col-12 col-lg-3">
        <h4 class="logo-default text-center text-lg-left">Skillustrator</h4>
      </div>

      <div class="col-12 col-lg-6">
        <ul class="nav nav-primary nav-hero">
          <li class="nav-item">
            <a class="nav-link" href="https://killeencode.com">Made with <i class="fa fa-heart"></i> by KilleenCode,
              LLC.</a>
          </li>
        </ul>
      </div>

      <div class="col-12 col-lg-3">
        <div class="social text-center text-lg-right">
          <a class="social-twitter" href="https://twitter.com/killeencode"><i class="fa fa-twitter"></i></a>
          <a class="social-facebook" href="https://www.facebook.com/KilleenCode"><i class="fa fa-facebook"></i></a>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- END Footer -->
