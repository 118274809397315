<!-- Topbar -->
<nav class="topbar topbar-inverse topbar-expand-md topbar-sticky">
  <div class="container">

    <div class="topbar-left">
      <a class="topbar-brand" href="/">
        <h4 class="logo-default">Skillustrator</h4>
        <h4 class="logo-inverse">Skillustrator</h4>
      </a>
    </div>


    <div class="topbar-right">
      <a href="/notyet" class="btn btn-sm btn-white mr-4" (click)="NavigatedToNotYet('LoginButton')">Login</a>
      <a href="/notyet" class="btn btn-sm btn-primary hidden-sm-down"
        (click)="NavigatedToNotYet('RegisterButton')">Register</a>
    </div>

  </div>
</nav>
<!-- END Topbar -->





<!-- Header -->
<header class="header fadeout header-inverse pb-0 h-fullscreen"
  style="background-image: linear-gradient(to bottom, #243949 0%, #517fa4 100%);">
  <canvas class="constellation" width="1536" height="451"></canvas>

  <div class="container">
    <div class="row h-full">
      <div class="col-12 text-center align-self-center">
        <h1 class="fs-50 fw-600 lh-15">Not yet!</h1>
        <br>

        <p class="fs-20"><strong>You're an early bird!</strong> You've found us just before launch.</p>
        <p class="fs-20">Add your e-mail address below and we'll make sure we keep you updated.</p>

        <form class="form-inline form-glass justify-content-center"
          action="https://killeencode.us19.list-manage.com/subscribe/post?u=76b5ecf5c4782e5a3278ee84b&amp;id=cd7e7f9c64"
          method="post" target="_blank" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form">

          <div class="form-group">
            <div class="input-group input-group-lg">
              <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
              <input type="email" id="mce-EMAIL" name="EMAIL" class="form-control" placeholder="Email Address"
                (focus)="EmailFocused()" (blur)="EmailUnfocused()" (input)="EmailTyping()" required>
            </div>
          </div>
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text"
              name="b_76b5ecf5c4782e5a3278ee84b_cd7e7f9c64" tabindex="-1" value=""></div>
          <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"
            class="btn btn-lg btn-primary ml-12" (click)="Subscribed()">

        </form>
        <hr class="w-60 hidden-sm-down">
        <div class="hidden-md-down">
          <h1 class="fs-50 fw-600 lh-15">Curious? Drop us a line.</h1>
          <form name="skillustrator-contact" class="p-30 form-glass  rounded" method="post"
            action="https://prod-60.eastus2.logic.azure.com:443/workflows/96716b789c86448593d9b39fad8f0e19/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=r2uwJtVKDNxvvocTUmAKchoqCjye3me56XO47ou_2Ak"
            target="_blank">
            <input type="hidden" name="form-name" value="skillustrator-contact" />

            <!-- <div class="alert alert-success">We received your message and will contact you back soon.</div> -->

            <div class="row">
              <div class="form-group col-12 col-md-6">
                <input class="form-control form-control-lg" type="text" name="name" placeholder="Your Name"
                  (focus)="ContactFormNameFocused()" (blur)="ContactFormNameUnfocused()"
                  (input)="ContactFormNameTyping()">
              </div>

              <div class="form-group col-12 col-md-6">
                <input class="form-control form-control-lg" type="email" name="email" placeholder="Your Email Address"
                  (focus)="ContactFormEmailFocused()" (blur)="ContactFormEmailUnfocused()"
                  (input)="ContactFormEmailTyping()">
              </div>
            </div>


            <div class="form-group">
              <textarea class="form-control form-control-lg" rows="4" placeholder="What's on your mind?" name="message"
                (focus)="ContactFormMessageFocused()" (blur)="ContactFormMessageUnfocused()"
                (input)="ContactFormMessageTyping()"></textarea>
            </div>

            <div class="text-center">
              <button class="btn btn-lg btn-primary" type="submit" (click)="ContactFormSubmitted()">Submit
                Enquiry</button>
            </div>
          </form>
        </div>
      </div>

      <div class="col-12 align-self-end text-center pb-70">
        <a class="scroll-down-2 scroll-down-inverse" href="#" data-scrollto="section-demo"><span></span></a>
      </div>
    </div>
  </div>
</header>
<!-- END Header -->
<main class="main-content hidden-lg-up">
  <section class="section">
    <div class="container">

      <h2 class="text-center">Curious? Drop us a line.</h2>
      <p class="text-center">We'd love to hear from you.</p>

      <br><br>

      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3">

          <form name="skillustrator-contact" class="p-30 bg-gray rounded" method="post"
            action="https://prod-60.eastus2.logic.azure.com:443/workflows/96716b789c86448593d9b39fad8f0e19/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=r2uwJtVKDNxvvocTUmAKchoqCjye3me56XO47ou_2Ak"
            target="_blank">
            <input type="hidden" name="form-name" value="skillustrator-contact" />

            <!-- <div class="alert alert-success">We received your message and will contact you back soon.</div> -->

            <div class="row">
              <div class="form-group col-12 col-md-6">
                <input class="form-control form-control-lg" type="text" name="name" placeholder="Your Name"
                  (focus)="ContactFormNameFocused()" (blur)="ContactFormNameUnfocused()"
                  (input)="ContactFormNameTyping()">
              </div>

              <div class="form-group col-12 col-md-6">
                <input class="form-control form-control-lg" type="email" name="email" placeholder="Your Email Address"
                  (focus)="ContactFormEmailFocused()" (blur)="ContactFormEmailUnfocused()"
                  (input)="ContactFormEmailTyping()">
              </div>
            </div>


            <div class="form-group">
              <textarea class="form-control form-control-lg" rows="4" placeholder="Your Message" name="message"
                (focus)="ContactFormMessageFocused()" (blur)="ContactFormMessageUnfocused()"
                (input)="ContactFormMessageTyping()"></textarea>
            </div>

            <div class="text-center">
              <button class="btn btn-lg btn-primary" type="submit" (click)="ContactFormSubmitted()">Submit
                Enquiry</button>
            </div>
          </form>

        </div>
      </div>

    </div>
  </section>


</main>






<!-- Footer -->
<footer class="site-footer">
  <div class="container">
    <div class="row gap-y align-items-center">
      <div class="col-12 col-lg-3">
        <h4 class="logo-default text-center text-lg-left">Skillustrator</h4>
      </div>

      <div class="col-12 col-lg-6">
        <ul class="nav nav-primary nav-hero">
          <li class="nav-item">
            <a class="nav-link" href="https://killeencode.com">Made with <i class="fa fa-heart"></i> by KilleenCode,
              LLC.</a>
          </li>
        </ul>
      </div>

      <div class="col-12 col-lg-3">
        <div class="social text-center text-lg-right">
          <a class="social-twitter" href="https://twitter.com/killeencode"><i class="fa fa-twitter"></i></a>
          <a class="social-facebook" href="https://www.facebook.com/KilleenCode"><i class="fa fa-facebook"></i></a>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- END Footer -->
