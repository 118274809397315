import { Component } from '@angular/core';
import {GoogleAnalyticsService} from '../../google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './notyet.html',
  styleUrls: ['./notyet.scss']
})
export class NotYetComponent {
  private analytics: GoogleAnalyticsService;
  private typingIntoEmailFieldAlreadyCaptured = false;
  private typingIntoContactFormNameFieldAlreadyCaptured = false;
  private typingIntoContactFormEmailFieldAlreadyCaptured = false;
  private typingIntoContactFormMessageFieldAlreadyCaptured = false;

  constructor(){
    this.analytics = new GoogleAnalyticsService();
  }
  public EmailFocused(): void {
    this.analytics.emitEvent('emailFieldFocused', 'signup', 'notyetPage');
  }
  public EmailUnfocused(): void {
    this.analytics.emitEvent('emailFieldUnfocused', 'signup', 'notyetPage');
    this.typingIntoEmailFieldAlreadyCaptured = false;
  }

  public ContactFormNameFocused(): void {
    this.analytics.emitEvent('contactFormNameFieldFocused', 'contact', 'notyetPage');
  }
  public ContactFormNameUnfocused(): void {
    this.analytics.emitEvent('contactFormEmailFieldUnfocused', 'contact', 'notyetPage');
    this.typingIntoContactFormNameFieldAlreadyCaptured = false;
  }

  public ContactFormNameTyping(): void {
    if (!this.typingIntoContactFormNameFieldAlreadyCaptured){
      this.analytics.emitEvent('typedInfoContactFormNameField', 'contact', 'notyetPage');
      this.typingIntoContactFormNameFieldAlreadyCaptured = true;
    }
  }

  public ContactFormEmailFocused(): void {
    this.analytics.emitEvent('contactFormEmailFieldFocused', 'contact', 'notyetPage');
  }

  public ContactFormEmailUnfocused(): void {
    this.analytics.emitEvent('contactFormEmailFieldUnfocused', 'contact', 'notyetPage');
    this.typingIntoContactFormEmailFieldAlreadyCaptured = false;
  }

  public ContactFormEmailTyping(): void {
    if (!this.typingIntoContactFormEmailFieldAlreadyCaptured){
      this.analytics.emitEvent('typedIntoContactFormEmailField', 'contact', 'notyetPage');
      this.typingIntoContactFormEmailFieldAlreadyCaptured = true;
    }
  }

  public ContactFormMessageFocused(): void {
    this.analytics.emitEvent('contactFormMessageFieldFocused', 'contact', 'notyetPage');
  }
  public ContactFormMessageUnfocused(): void {
    this.analytics.emitEvent('contactFormMessageFieldUnfocused', 'contact', 'notyetPage');
    this.typingIntoContactFormMessageFieldAlreadyCaptured = false;
  }

  public ContactFormMessageTyping(): void {
    if (!this.typingIntoContactFormMessageFieldAlreadyCaptured){
      this.analytics.emitEvent('typedIntoContactFormMessageField', 'contact', 'notyetPage');
      this.typingIntoContactFormMessageFieldAlreadyCaptured = true;
    }
  }

  public ContactFormSubmitted(): void {
    this.analytics.emitEvent('contactFormSubmitted', 'contact', 'notyetPage');
  }

  public EmailTyping(): void {
    if (!this.typingIntoEmailFieldAlreadyCaptured){
      this.analytics.emitEvent('typedIntoEmailField', 'signup', 'notyetPage');
      this.typingIntoEmailFieldAlreadyCaptured = true;
    }
  }
  public Subscribed(): void {
    this.analytics.emitEvent('subscribedToEmails', 'signup', 'notyetPage');
  }

  public NavigatedToNotYet(fromwhere: string): void {
    this.analytics.emitEvent('from'+ fromwhere, 'navigatedToNotYet', 'notyetPage');
  }

}
