// https://medium.com/madhash/how-to-properly-add-google-analytics-tracking-to-your-angular-web-app-bc7750713c9e

import { Injectable } from '@angular/core';

declare let gtag: (command: string, id: string, moreInfo: object) => void;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  public emitEvent(
    eventAction: string,
    eventCategory: string,
    eventLabel: string = null,
    eventValue: number = null,
    nonInteraction: boolean = false )
    {
      gtag('event', eventAction, {
        'event_category': eventCategory,
        'event_label': eventLabel,
        'event_value': eventValue,
        'non_interaction': nonInteraction
      });
    }
  }
